<template>
    <div class="action-recommend-accept">
        <div class="title" v-html="title" />
        <div class="body" v-html="body" v-if="body" />
        <div v-if="user" class="bottom flex-row items-center">
            <div @click="onClickProfile" class="flex-row items-center flex-fill cursor-pointer">
                <div class="img-cover flex-wrap" v-img-cover="user.photo_url" />
                <div>프로필 보기</div>
            </div>
            <div class="divider flex-wrap">|</div>
            <div class="functions flex-row flex-wrap">
                <ChatButton class="button recommended" :user="user" :source_id="$store.getters.chat.user.id" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionRecommendAccept',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        title() {
            return this.$translate('ACTION_RECOMMEND_ACCEPT_TITLE').replace(/%s/, this.$nameOrNick(this.user))
        },
        body() {
            return this.$translate('ACTION_RECOMMEND_ACCEPT_BODY')
        },
        user() {
            const user = (this.$mustParse(this.message.content) || {}).user
            const chatId = (this.$getChat(user.id) || {}).id
            if (chatId) user.$$chatId = chatId
            return user
        },
        accepted() {
            return this.chat.user
        },
    },
    methods: {
        async onClickProfile() {
            try {
                await this.$store.dispatch('loadIntroducingUser', this.user.id)
                if (this.$store.getters.isMobile) {
                    this.$router.push({
                        name: 'UserDetailPage',
                        params: {
                            userId: this.user.id,
                        },
                    })
                }
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
    },
}
</script>
